import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const style = {
    boxShadow: '0 11px 15px -7px rgba(0,0,0,.2), 0 24px 38px 3px rgba(0,0,0,.14), 0 9px 46px 8px rgba(0,0,0,.12)',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'white',
  borderRadius: "2px",
  padding: '24px',
  outline: 0
};

export default function Buscador({open, setOpen}) {
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false)
    setParaula('');
};

  const [paraula, setParaula] = React.useState('');
  const navigate = useNavigate();

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Typography id="transition-modal-title" variant="h2" margin={'20px 0'} fontWeight={'800'} fontSize={'24px'}>
              Cerca
            </Typography>
            <TextField
                helperText={"Mínim 3 caràcters"}
                fullWidth
                label={"Escriu paraules clau que vols buscar"}
                name={"buscador"}
                type={"text"}
                size={"medium"}
                variant="filled"
                InputLabelProps={{
                    style: {
                        fontSize: 18,
                    },
                }}
                onChange={(event) => {
                    setParaula(event.target.value);
                }}
            />
            <Box sx={{padding: '12px 0'}}>
                <Button onClick={() => {setOpen(false)}}>Cancel·la</Button>
                <Button 
                    variant='outlined' 
                    disabled={paraula.length >= 3 ? false : true} 
                    sx={{marginLeft: '10px'}} 
                    onClick={() => {
                        navigate('/cerca/' + paraula);
                        setOpen(false);
                    }}
                >Cerca</Button>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}