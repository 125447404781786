import React, { useCallback, useEffect, useState } from "react";
import Page from "../../components/layouts/Page";
import { get } from "../../API/API";
import { Box, Card, CardContent, Container, Grid, Typography } from "@mui/material";
import Caixa from "../../components/utils/Caixa";
import Posts from "../../components/utils/Posts";
import { motion, AnimatePresence } from "framer-motion";
import Footer from "../../layouts/public/Footer";
import CaixaHome from "../../components/utils/CaixaHome";
import XXSS from "../../components/utils/XXSS";

function Home(props) {

    const [loading, setLoading] = useState(true);
    const [pages, setPages] = useState([]);
    const [menu, setMenu] = useState([]);
    const [background, setBackground] = useState([]);
    const [posts, setPosts] = useState([]);
    const [width, setWidth] = useState(100);
	const div = useCallback((node) => {
		if (node !== null) {
			setWidth(node.getBoundingClientRect().width);
		}
	}, []);

    useEffect(() => {
        const obtenir = async () => {
            const { result, message } = await get("menu");
            setMenu(result);
        };
        obtenir();

        const obtenirPages = async () => {
            const { result, message } = await get("pages");
            setPages(result);
        };
        obtenirPages();

        const obtenirPosts = async () => {
            const { result, message } = await get("posts");
            setPosts(result.slice(0, 24));
            setLoading(false);
        };
        obtenirPosts();

        const obtenirBackground = async () => {
            const { result, message } = await get("background");
            setBackground(result);
        };
        obtenirBackground();
    }, []);
	
	function getGridSize(size) {
        switch (size) {
            case "1":
                return 4;
            case "2":
                return 6;
            case "3":
                return 12;
            default: 
                return 4
        }
    }
    
    return (
        <Page title="ADBerguedà" timeout={3000} padding={10}>
            {/* <Box>
                <img src={background?.background} style={{width: '100%', height: '100%', objectFit: 'cover'}}/>
            </Box> */}
            <Box
                display={props.isMobile ? 'block' : 'flex'}
                position={'relative'} 
                height={props.isMobile ? '100%' : '100vh'} 
                width={'100%'} 
                margin={'auto'} 
                sx={{
                    backgroundColor: "white",
                    backgroundImage: `url(`+background?.background+`)`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    overflowY: 'auto'
                }}
            >
                <Container sx={{maxWidth: '1200px', display: props.isMobile ? 'block' : 'flex', alignItems: 'center'}}>
                    <Grid 
                        container 
                        spacing={2}
                        paddingTop={props.isMobile ? '90px' : 0}
                    >
                        <Grid item md={6} xs={12} sx={{height:'482px'}} order={{ xs: 2, sm: 1 }}>
                            <Box ref={div} width={'100%'} height={'100%'}>
                                <Posts posts={posts} width={width} loading={loading}/>
                            </Box>
                        </Grid>
                        <Grid item md={6} xs={12} order={{ xs: 1, sm: 2 }}>
                            <motion.div
                                className="box"
                                initial={{ opacity: 0, scale: 0.5 }}
                                animate={{ opacity: 1, scale: 1 }}
                                transition={{
                                    duration: 0.8,
                                    delay: 0.1,
                                    ease: [0, 0.71, 0.2, 1.01]
                                }}
                            >
                                <Grid container spacing={props.isMobile ? 0 : 1}>
                                    {menu?.map((pagina, index) => {
                                        return (
                                            <Grid key={index} item md={getGridSize(pagina?.size)} xs={getGridSize(pagina?.size)}>
                                                <CaixaHome data={pagina} isMobile={props.isMobile}/>
                                            </Grid>
                                        )
                                    })}
                                </Grid>
                            </motion.div>
                        </Grid>
                    </Grid>
                    <XXSS social={props.web?.social} isMobile={props.isMobile}/>
                </Container>
                <Footer isMobile={props.isMobile} text_footer={props.web?.text_footer} subtext_footer={props.web?.subtext_footer} color={pages?.main_color} sponsors={props.web?.sponsors}/>

            </Box>
        </Page>
    );
}

export default Home;
