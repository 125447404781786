import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import TopBarPublic from "./TopBarPublic";
import Footer from "./Footer";
import { Drawer } from "@mui/material";

import { motion, AnimatePresence } from "framer-motion";
import Buscador from "../../components/utils/Buscador";

const useStyles = makeStyles((theme) => ({
	root: {
		height: "100%",
		overflow: "hidden",
		width: "100%",
		backgroundColor: "black"
	},
	wrapper: {
		display: "flex",
		flex: "1 1 auto",
		overflow: "hidden",
		width: '100%',
	},
	contentContainer: {
		display: "flex",
		flex: "1 1 auto",
		overflow: "hidden",
	},
	content: {
		flex: "1 1 auto",
		height: "100%",
		// overflow: "auto",
	},
}));

const DashboardLayout = (props) => {
    const [sidebar, setSidebar] = useState(false);
	const [open, setOpen] = useState(false);
	const classes = useStyles();

	const toggleDrawer = (open) => (event) => {
        if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
            return;
        }
        setSidebar(open);
    };
	const variants = {
		hidden: { opacity: 0, x: 0, y: 50 },
		enter: { opacity: 1, x: 0, y: 0 },
		exit: { opacity: 0, x: 0, y: -100 },
	};

	return (
		<div className={classes.root}>

			<TopBarPublic isMobile={props.isMobile} toggleDrawer={toggleDrawer} menu={props.web?.menu} setOpen={setOpen} open={open}/>
			<motion.div
                variants={variants} // Pass the variant object into Framer Motion
                initial="hidden" // Set the initial state to variants.hidden
                animate="enter" // Animated state to variants.enter
                exit="exit" // Exit state (used later) to variants.exit
                transition={{ type: "linear" }}
            >
                <AnimatePresence>
					<div className={classes.wrapper}>
						<div className={classes.contentContainer}>
							<div className={classes.content}>
								<Outlet/>
								<Buscador open={open} setOpen={setOpen}/>
							</div>
						</div>
					</div>
				</AnimatePresence>
			</motion.div>
		</div>
	);
};

export default DashboardLayout;
