import axios from "axios";
import moment from "moment";

//const url = "http://127.0.0.1:8000/api/";
const url = "https://adbergueda.herokuapp.com//api/v1/";

const Login = async (email, password) => {
	let message;
	await axios
		.post(url + "login", {
			email: email,
			password: password,
			remember_me: true,
		})
		.then((response) => {
			if (response.status === 200) {
				localStorage.setItem("isLoggedIn", true);
				localStorage.setItem("user", JSON.stringify(response.data));
				message = "Login amb èxit";
			}
			if (
				response.data.status === "failed" &&
				response.data.success === undefined
			) {
				message = "error";
			}
		})
		.catch((error) => {
			message = "error";
			console.log(error);
		});

	return message;
};

const get = async (element, key) => {
	const user = JSON.parse(localStorage.getItem("user"));
	let message;
	let result;
	let config = {
		headers: {
			"Access-Control-Allow-Origin": "*",
			Authorization: "Bearer " + user?.token,
		},
	};
	await axios
		.get(url + element , config)
		.then((response) => {
			if (response.status === 200) {
				result = response.data;
				message = "Obtingut amb èxit";
			}
			if (
				response.data.status === "failed" &&
				response.data.success === undefined
			) {
				message = "No s'han trobat";
			}
		})
		.catch((error) => {
			console.log(error);
		});

	return { result, message };
};


const getElement = async (element, key) => {
	const user = JSON.parse(localStorage.getItem("user"));
	let message;
	let result;
	let config = {
		headers: {
			"Access-Control-Allow-Origin": "*",
			Authorization: "Bearer " + user?.token,
		},
	};
	await axios
		.get(url + element + "/" + key, config)
		.then((response) => {
			if (response.status === 200) {
				result = response.data.data;
				message = "Obtingut amb èxit";
			}
			if (
				response.data.status === "failed" &&
				response.data.success === undefined
			) {
				message = "No s'han trobat";
			}
		})
		.catch((error) => {
			console.log(error);
		});

	return { result, message };
};

const getAdmin = async (element) => {
	const user = JSON.parse(localStorage.getItem("user"));
	let message;
	let result;
	let config = {
		headers: {
			"Access-Control-Allow-Origin": "*",
			Authorization: "Bearer " + user?.token,
		},
	};
	await axios
		.get(url + element, config)
		.then((response) => {
			if (response.status === 200) {
				result = response.data.data;
				message = "Obtingut amb èxit";
			}
			if (
				response.data.status === "failed" &&
				response.data.success === undefined
			) {
				message = "No s'han trobat";
			}
		})
		.catch((error) => {
			console.log(error);
		});

	return { result, message };
};

const getUser = async () => {
	const user = JSON.parse(localStorage.getItem("user"));
	let message;
	let data;
	let config = {
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + user?.token,
		},
	};
	await axios
		.get(url + "user", config)
		.then((response) => {
			if (response.status === 200) {
				message = response.status;
				data = response.data;
			}
			if (
				response.data.status === "failed" &&
				response.data.success === undefined
			) {
				message = "401";
			}
		})
		.catch((error) => {
			if (error.response.status === 401) {
				localStorage.removeItem("user");
			}
		});
	return { message, data };
};

const registreAdministrador = async (values) => {
	let message;
	await axios
		.post(url + "registreAdministrador", values)
		.then((response) => {
			if (response.status === 200) {
				message = "Usuari creat";
			}
			if (
				response.data.status === "failed" &&
				response.data.success === undefined
			) {
				message = "error";
			}
		})
		.catch((error) => {
			message = "error";
			console.log(error);
		});

	return message;
};

const registreJugador = async (values) => {
	let message;
	await axios
		.post(url + "registreJugador", values)
		.then((response) => {
			if (response.status === 200) {
				message = "Usuari creat";
			}
			if (
				response.data.status === "failed" &&
				response.data.success === undefined
			) {
				message = "error";
			}
		})
		.catch((error) => {
			message = "error";
			console.log(error);
		});

	return message;
};

const deleteElement = async (element, key) => {
	const user = JSON.parse(localStorage.getItem("user"));
	let message;
	let config = {
		headers: {
			"Access-Control-Allow-Origin": "*",
			Authorization: "Bearer " + user?.token,
		},
	};
	await axios
		.delete(url + element + "/" + key, config)
		.then((response) => {
			console.log(response);
			if (response.status === 200) {
				message = response.data.message;
			}
			if (
				response.data.status === "failed" &&
				response.data.success === undefined
			) {
				message = response.data.message;
			}
		})
		.catch((error) => {
			console.log(error);
		});
	return message;
};

const updateElement = async (element, key, values) => {

	const user = JSON.parse(localStorage.getItem("user"));
	let result = {
        message: '',
        data: null,
    };
	let config = {
		headers: {
			"Access-Control-Allow-Origin": "*",
			Authorization: "Bearer " + user?.token,
		},
	};
	var form_data = new FormData();

    for (var i in values) {
        form_data.append(i, values[i]);
    }
	await axios
		.post(url + element + "/" + key, form_data, config)
		.then((response) => {
			console.log(response);
			if (response.status === 200) {
				result.message = response.data.message;
				result.data = response.data.data;
			}
			if (
				response.data.status === "failed" &&
				response.data.success === undefined
			) {
				result.message = response.data.message;
			}
		})
		.catch((error) => {
			console.log(error);
		});
	return result;
};

const sendEmail = async (element, values) => {
    let message;
	let success = false;
    var form_data = new FormData();

    for (var key in values) {
        form_data.append(key, values[key]);
    }
	await axios
	.post(url + element, form_data)
	.then((response) => {
		if (response.status === 200) {
			message = response.data.message;
			success = response.data.success;
		}
		if (
			response.data.status === "failed" &&
			response.data.success === undefined
		) {
			message = response.data.message;
		}
	})
	.catch((error) => {
		console.log(error);
	});

    return message;
};

const create = async (element, values) => {
	const user = JSON.parse(localStorage.getItem("user"));
	let message;
	let success = false;
	let config = {
		headers: {
			"Access-Control-Allow-Origin": "*",
			Authorization: "Bearer " + user?.token,
		},
	};
    var form_data = new FormData();

    for (var key in values) {
        form_data.append(key, values[key]);
    }
	await axios
		.post(url + element, form_data, config)
		.then((response) => {
			if (response.status === 200) {
				message = response.data.message;
				success = response.data.success;
			}
			if (
				response.data.status === "failed" &&
				response.data.success === undefined
			) {
				message = response.data.message;
			}
		})
		.catch((error) => {
			console.log(error);
		});
	return { message, success };
};

const createOrder = async (element, values) => {
    const user = JSON.parse(localStorage.getItem("user"));
	let result = {
        message: '',
        data: null,
    };
	let config = {
		headers: {
			"Access-Control-Allow-Origin": "*",
			Authorization: "Bearer " + user?.token,
		},
	};
    var form_data = new FormData();

    for (var key in values) {
        form_data.append(key, values[key]);
    }
	await axios
		.post(url + element, form_data, config)
		.then((response) => {
			if (response.status === 200) {
				result.message = response.data.message;
				result.success = response.data.success;
				result.data = response.data.data;
			}
			if (
				response.data.status === "failed" &&
				response.data.success === undefined
			) {
				result.message = response.data.message;
			}
		})
        .catch((e) => {
            result.message = 'error';
            console.log(e);
        });

    return result;
};

const createOrderProduct = async (element, values) => {
    const user = JSON.parse(localStorage.getItem("user"));
	let result = {
        message: '',
        data: null,
    };
	let config = {
		headers: {
			"Access-Control-Allow-Origin": "*",
			Authorization: "Bearer " + user?.token,
		},
	};
    var form_data = new FormData();

    for (var key in values) {
        form_data.append(key, values[key]);
    }
	await axios
		.post(url + element, form_data, config)
		.then((response) => {
			if (response.status === 200) {
				result.message = response.data.message;
				result.success = response.data.success;
				result.data = response.data.data;
			}
			if (
				response.data.status === "failed" &&
				response.data.success === undefined
			) {
				result.message = response.data.message;
			}
		})
        .catch((e) => {
            result.message = 'error';
            console.log(e);
        });

    return result;
};



const changeState = async (id, values) => {
	const user = JSON.parse(localStorage.getItem("user"));

	const data = new FormData();
	for (var key in values) {
        data.append(key, values[key]);
    }

	let message;
	let status;

	let config = {
		headers: {
			"Content-Type": "multipart/form-data",
			Accept: "application/json",
			"Access-Control-Allow-Origin": "*",
			Authorization: "Bearer " + user?.token,
		},
	};

	await axios
		.post(url + "canviarEstatComanda/" + id, data, config)
		.then((response) => {
			if (response.status === 200) {
				message = "Actualitzat";
				status = "success";
			} else {
				console.log("ERRORRR");
				message = response.data.message;
				status = "error";
			}
		})
		.catch((error) => {
			message = error.response.data.message;
			status = "error";
		});

	return { message, status };
};

const getCookie = (name) => {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            // Does this cookie string begin with the name we want?
            if (cookie.substring(0, name.length + 1) === name + '=') {
                cookieValue = decodeURIComponent(
                    cookie.substring(name.length + 1)
                );
                break;
            }
        }
    }
    return cookieValue;
};

export {
	Login,
	registreAdministrador,
	registreJugador,
    get,
    getElement,
    getUser,
    create,
    deleteElement,
    updateElement,
    createOrder,
    createOrderProduct,
    sendEmail,
	getAdmin,
	changeState
}
