import { Box, Button, Link, Typography } from "@mui/material";

import { motion, AnimatePresence } from "framer-motion";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
export default function Caixa({ data, pages, isMobile }) {
    const [scale, setScale] = useState(1);
    const navigate = useNavigate();
    function onCaixa() {
        setScale(1.03);
    }
    function outCaixa() {
        setScale(1);
    }
    function onClickCaixa() {
        if (data.external_link !== null && data.external_link !== "") {
            if (data.internal_link) {
                window.location.href = data.external_link;
            } else {
                window.open(data.external_link, '_blank'); 
            }
        } else {
            navigate('/'+pages?.slug+'/'+data?.url);
        }
    }
	return (
            
        <Box
            style={{ 
                width: "100%", 
                height: "100%", 
                background: data?.background, 
                display: 'flex', 
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                height: '150px',
                borderRadius: isMobile ? 0 : '10px',
                cursor: 'pointer',
                transform: 'scale('+scale+')',
                transition: '.2s'
            }}
            onMouseEnter={onCaixa}
            onMouseLeave={outCaixa}
        >
            <Button onClick={() => {onClickCaixa()}}  mx={2} display={'flex'} sx={{textDecoration: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                <img src={data?.icon} style={{width: '65px', height: '65px', margin: 0}}/>
                <Typography variant="caixa" color={"#fff"} textAlign={'center'}>{data?.name}</Typography>
            </Button>
        </Box>
	);
}
