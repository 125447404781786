import React, { useCallback, useEffect, useState } from "react";
import Page from "../../components/layouts/Page";
import { useParams } from "react-router-dom";
import { get } from "../../API/API";
import { Box, Card, CardContent, Container, Grid, Link, Typography } from "@mui/material";
import Caixa from "../../components/utils/Caixa";
import { motion, AnimatePresence } from "framer-motion";
import Posts from "../../components/utils/Posts";
import Footer from "../../layouts/public/Footer";
import XXSS from "../../components/utils/XXSS";

function QuartaPagina(props) {

	const [loading, setLoading] = useState(true);
    const [pagines, setPagines] = useState([]);
    const [posts, setPosts] = useState([]);
    const [pages, setPages] = useState([]);
    const { name, id, desc, quart } = useParams();

    const [width, setWidth] = useState(100);
	const div = useCallback((node) => {
		if (node !== null) {
			setWidth(node.getBoundingClientRect().width);
		}
	}, []);

    const variants = {
		hidden: { opacity: 0, x: 0, y: 50 },
		enter: { opacity: 1, x: 0, y: 0 },
		exit: { opacity: 0, x: 0, y: -100 },
	};

    useEffect(() => {
        const obtenir = async () => {
            const { result, message } = await get("menu/" + quart);
            setPagines(result);
            setLoading(false);
        };
        obtenir();

        const obtenirPosts = async () => {
            const { result, message } = await get("posts/" + quart);
            setPosts(result);
        }
        obtenirPosts();

        const obtenirPages = async () => {
            const { result, message } = await get("pages/" + quart);
            setPages(result);
        }
        obtenirPages();
      }, []);


    
	return (
		<Page title="Agència de desenvolupament del Berguedà" timeout={3000} padding={10} paddingTop={60}>
			<Box 
                display={props.isMobile ? 'block' : 'flex'}
                position={'relative'} 
                height={props.isMobile ? '100%' : '100vh'} 
                width={'100%'} 
                margin={'auto'} 
                sx={{
                    backgroundColor: "#eaeaea",
                    backgroundImage: `url(`+pages?.image_background?.url+`)`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    overflowY: 'auto'
                }}
            >
                <Container maxWidth='xl' sx={{maxWidth: '1200px', paddingTop: '90px'}}>
                    <Grid 
                        container 
                        spacing={2} 
                        paddingTop={props.isMobile ? '90px' : 0}
                        width={'auto'}
                        sx={{
                            maxWidth: (pages?.childs?.length > 0 ? '830px' : "1200px"),
                            margin: 'auto',
                            padding: "100px 0"
                        }}
                    >
                        {pages?.childs?.length > 0 ? 
                            <>
                                <Grid item md={12} xs={12} >
                                    <Box sx={{background: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '10px'}}>
                                        <Typography variant="h2" fontSize={'24px'} fontWeight={800} textAlign={'center'}>{pages?.name}</Typography>
                                    </Box>
                                </Grid>
                                {pagines.map((pagina, index) => {
                                    return (
                                        <Grid item md={6} xs={12}>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    background: 'white',
                                                    cursor: 'pointer',
                                                    background: 'white',
                                                    height: '150px'
                                                }}
                                            >
                                                <a 
                                                    href={(pagina?.no_link ? null : (pagina?.external_link == "" || pagina?.external_link == null ? pages?.slug+'/'+pagina?.url : pagina.external_link))}
                                                    mx={2} 
                                                    display={'flex'} 
                                                    padding={'10px'}
                                                    target={pagina?.external_link == "" || pagina?.external_link == null ? "" : "_blank"}
                                                    style={{
                                                        textDecoration: 'none',
                                                        margin: '0 !important', 
                                                        width: '100%',
                                                        display: 'flex',
                                                        margin: '10px',
                                                        overflow: 'hidden'
                                                    }}
                                                >                                                    <img src={pagina.image} alt="image" style={{width: '130px', height: '130px', margin: 0}}/>
                                                    <Box
                                                        sx={{
                                                            marginLeft: '10px'
                                                        }}
                                                    >
                                                        <Typography variant="h3" height={'40px'} fontSize={'14px'} color={'#575757'} fontWeight={700}>{pagina.name}</Typography>
                                                        <Typography
                                                            className="segona-pagina"
                                                            fontSize={'12px'}
                                                            width={'100%'}
                                                            dangerouslySetInnerHTML={{ __html: pagina.description }}
                                                        ></Typography>
                                                    </Box>
                                                </a>
                                            </Box>
                                        </Grid>
                                    )
                                })}
                            </>
                            :
                            <>
                                <Box sx={{marginTop: props.isMobile ? "0" : "90px", marginBottom: props.isMobile ? 0 : "90px", background: 'white'}}>
                                    {pages?.image_header != null ? <Box sx={{width: '100%', height: props.isMobile ? 'auto' : 200, overflow: 'hidden'}}><img src={pages?.image_header} alt="image_header" style={{width: '100%', objectFit: 'cover', margin: 0}}/></Box> : <></>}
                                    {pages?.name != "" ? <Typography variant="h3" fontSize={'24px'} margin={'20px 0px'} padding={'40px 20px 0'} color={'#575757'} fontWeight={800}>{pages?.name}</Typography> : <></>}
                                    <Grid container sx={{position: 'relative'}}>
                                        <Grid item md={8} xs={12}>
                                            <Typography
                                                padding={2}
                                                dangerouslySetInnerHTML={{ __html: pages?.content }}
                                            ></Typography>
                                        </Grid>
                                        {pages?.image_right != null ? 
                                            <Grid item md={4} xs={12}>
                                                {props.isMobile ? <></> : <img 
                                                    src={pages?.image_right} 
                                                    alt="image_header" 
                                                    style={{
                                                        display: 'block', 
                                                        position: 'absolute',
                                                        right: '50px',
                                                        top: '200px',
                                                        width: '200px',
                                                        height: '200px',
                                                        borderRadius: '50%',
                                                        margin: 0
                                                    }}
                                                />}
                                            </Grid> :
                                            <></>
                                        }
                                    </Grid>
                                </Box>
                            </>
                        }
                    </Grid>
                    <XXSS isMobile={props.isMobile} social={props.web?.social}/>
                </Container>
			    <Footer isMobile={props.isMobile} text_footer={props.web?.text_footer} subtext_footer={props.web?.subtext_footer} color={pages?.main_color} sponsors={props.web?.sponsors}/>
            </Box>
		</Page>
	);
}

export default QuartaPagina;
