import React, { useCallback, useEffect, useRef, useState } from "react";
import Page from "../../components/layouts/Page";
import { useNavigate, useParams } from "react-router-dom";
import { get } from "../../API/API";
import { Box, Button, Card, CardContent, Container, Grid, Skeleton, Typography } from "@mui/material";
import Caixa from "../../components/utils/Caixa";
import { motion, AnimatePresence, useInView } from "framer-motion";
import Posts from "../../components/utils/Posts";
import Footer from "../../layouts/public/Footer";
import XXSS from "../../components/utils/XXSS";
import background from "../../images/posts-background.jpg";
import { styled } from "@mui/material/styles";

function Noticies(props) {

	const [loading, setLoading] = useState(false);
    const [posts, setPosts] = useState([]);
    const [pages, setPages] = useState([]);
	const [limit, setLimit] = useState(14);
    const [total, setTotal] = useState(0);
    const [highlight, setHighlight] = useState(false);
    const navigate = useNavigate();

    const ref = useRef(null)
    const isInView = useInView(ref);

    let count = 0;

    useEffect(() => {
        if (limit > 14) {
            const element = document.getElementById(count-14);
            element.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
        }
    }, [limit])

    useEffect(() => {
        setTotal(0);
        setLimit(14);
        var myDiv = document.getElementById('container');
        myDiv.scrollTop = 0;
    }, [highlight])

    useEffect(() => {

        const obtenirPosts = async () => {
            try {
				setLoading(true);
                const { result, message } = await get("posts");
                setPosts(result);
                setTotal(result.length);
			} catch (err) {
				console.log(err);
			} finally {
				setLoading(false);
			}
        }
        obtenirPosts();
      }, []);


    
	return (
		<Page title="ADBerguedà" timeout={1000} padding={10}>
			<Box 
                id="container"
                display='flex' 
                position={'relative'} 
                height={'100vh'} 
                width={'auto'} 
                margin={'auto'} 
                sx={{
                    backgroundColor: "white",
                    backgroundImage: `url(`+background+`)`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    overflowY: 'auto',
                }}
            >
                <Container maxWidth={props.isMobile ? 'xs' : 'md'} disableGutters={props.isMobile ? true : false}>
                    <Grid
                        container={props.isMobile ? false : true}
                        spacing={2}
                        width={'100%'}
                        sx={{
                            paddingTop: '100px',
                            paddingBottom: '100px'
                        }}
                    >
                        {props.isMobile ? <Box 
                            style={{ 
                                zIndex: 3,
                                position: "relative",
                                transition: '.2s',
                                width: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                marginBottom: '20px'
                            }}
                        >
                            <Button 
                                variant="contained" 
                                color="info" 
                                sx={{
                                    background: (highlight ? 'white' : '#cdcdcd'),
                                    fontFamily: 'Montserrat',
                                    width: '50%',
                                    height: '46px',
                                    borderRadius: 0
                                }}
                                onClick={() => {
                                    setHighlight(false)
                                }}
                            >Notícies</Button>
                            <Button 
                                variant="contained" 
                                color="info"
                                sx={{
                                    background: (highlight ? '#cdcdcd' : 'white'),
                                    fontFamily: 'Montserrat',
                                    width: '50%',
                                    height: '46px',
                                    borderRadius: 0
                                }}
                                onClick={() => {
                                    setHighlight(true)
                                }}
                            >Destacats</Button>
                        </Box> : <></>}
                        {posts?.map((post, index) => {
                            if (count < limit && post.highlight == highlight) {
                                count = count + 1
                                return (
                                    <motion.div
                                        id={count}
                                        className="box"
                                        initial={{ opacity: 0, y: 100, width: '100%' }}
                                        animate={{ opacity: 1, y: 0 }}
                                        transition={{
                                            duration: 0.8,
                                            delay: 0.2,
                                            ease: [0, 0.71, 0.2, 1.01]
                                        }}
                                        key={index}
                                    >
                                        <Grid 
                                            item 
                                            md={12}
                                            sx={{
                                                display: 'flex',
                                                width: '100%',
                                                height: 150,
                                                marginBottom: '20px',
                                                background: 'white',
                                                overflow: 'hidden'
                                            }}
                                        >
                                            <NoticiaCustom sx={{}}  onClick={() => { navigate('/noticia/' + post?.slug) }}>
                                                <img src={post.image} alt={post.title} style={{width: 200, height: 150, margin: 0}}/>
                                                <Box p={'10px'} pl={'20px'}>
                                                    <Typography variant="body1">{post.day}</Typography>
                                                    <Typography variant="h3" fontWeight={'bold'}>{post.title}</Typography>
                                                    <Typography variant="body1">{post.description?.substring(0, 270) + "..."}</Typography>
                                                </Box>
                                            </NoticiaCustom>
                                        </Grid>
                                    </motion.div>
                                )
                            }
                        })}
                        {count < total && (
                            <Box textAlign={"center"} margin={'0 auto'}>
                                <Button 
                                    variant="contained" 
                                    color="info" 
                                    onClick={() => {
                                        setLimit((prev) => prev + 14)
                                    }}
                                >Veure'n més</Button>
                            </Box>
                        )}
                    </Grid>
                    <XXSS social={props.web?.social} isMobile={props.isMobile}/>
                </Container>
                {props.isMobile ? <></> : <Box 
                    style={{ 
                        zIndex: 3,
                        position: "fixed",
                        left: -40,
                        top: '50%',
                        transform: 'translateY(-50%)',
                        transition: '.2s',
                        width: 200,
                        display: 'flex',
                        flexDirection: 'column'
                    }}
                >
                     <Button 
                        variant="contained" 
                        sx={{
                            background: (highlight ? 'white' : '#cdcdcd'),
                            fontFamily: 'Montserrat',
                            height: '60px',
                            textTransform: 'capitalize',
                            fontSize: '16px',
                            color: '#575756'
                        }}
                        onClick={() => {
                            setHighlight(false)
                        }}
                    >Notícies</Button>
                    <Button 
                        variant="contained"
                        sx={{
                            background: (highlight ? '#cdcdcd' : 'white'),
                            marginTop: '10px',
                            fontFamily: 'Montserrat',
                            height: '60px',
                            textTransform: 'capitalize',
                            fontSize: '16px',
                            color: '#575756'
                        }}
                        onClick={() => {
                            setHighlight(true)
                        }}
                    >Destacats</Button>
                </Box>}
			    <Footer text_footer={props.web?.text_footer} subtext_footer={props.web?.subtext_footer} color={pages?.main_color} sponsors={props.web?.sponsors}/>
            </Box>
		</Page>
	);
}

const NoticiaCustom = styled(Box)(({ theme }) => ({
	backgroundColor: 'transparent', 
    cursor: 'pointer',
    display:'flex',
    width:'100%',
    transition: '.2s',
	"&:hover": {
		background: '#cdcdcd'
	},
}));

export default Noticies;
