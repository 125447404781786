import { Box, Button, Collapse, Container, Grid, Link, Typography } from "@mui/material";

import { motion, AnimatePresence } from "framer-motion";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Page from "../layouts/Page";
import Footer from "../../layouts/public/Footer";
import XXSS from "./XXSS";
import PrintIcon from '@mui/icons-material/Print';
export default function Ofertes({ web, pages, isMobile }) {
    const [collapsedJobs, setCollapsedJobs] = useState({});
    const navigate = useNavigate();
    
    function onClickJob(id) {
        setCollapsedJobs(prevState => ({
            ...prevState,
            [id]: !prevState[id]
        }));
    }

	return (
        <Page title="ADBerguedà" timeout={3000} padding={10} paddingTop={60}>
                <Box 
                    display={isMobile ? 'block' : 'flex'}
                    position={'relative'} 
                    height={isMobile ? '100%' : '100vh'} 
                    width={'100%'} 
                    margin={'auto'} 
                    sx={{
                        backgroundColor: "#eaeaea",
                        backgroundImage: `url(`+pages?.image_background?.url+`)`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        overflowY: 'auto'
                    }}
                >
                    <Container maxWidth={isMobile ? 'xs' : 'xl'} disableGutters={isMobile ? true : false} sx={{maxWidth: '1200px'}} padding={isMobile ? '0' : 'unset'}>
                        <Grid 
                            container={isMobile ? false : true}
                            spacing={2} 
                            paddingTop={isMobile ? '90px' : 0}
                            width={'auto'}
                            sx={{
                                padding: "100px 0",
                                maxWidth: (pages?.childs?.length > 0 ? '830px' : "1200px"),
                                margin: 'auto',
                                width: '100%'
                            }}
                        > 
                            <Grid item md={12} xs={12}>
                                <Box sx={{background: 'transparent', height: '50px', display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative'}}>
                                    <Typography variant="h1" fontSize={'24px'} fontWeight={500} textAlign={'center'} textTransform={'uppercase'}>{pages.name}</Typography>
                                    <a class="printableIcon" target="blank" href="https://adbergueda.herokuapp.com/imprimir/ofertes-de-feina.pdf"><PrintIcon htmlColor="#333"/></a>
                                </Box>
                            </Grid>
                            {isMobile ? <></> :
                            <Grid item md={12} xs={12}>
                                <ul>
                                    <li className="li-first">NOM OFERTA</li>
                                    <li className="li-second">LLOC</li>
                                    <li className="li-third">+ INFO</li>
                                </ul>
                            </Grid>}
                            {pages?.offer_categories?.map((offer) => {
                                return (
                                    <Grid item md={12} xs={12}>
                                        <Box sx={{background: 'transparent', height: '44px', display: 'flex', alignItems: 'center', justifyContent: 'center', borderBottom: '2px solid #d1185a', margin: '40px 0'}}>
                                            <Typography variant="h2" fontSize={'19px'} fontWeight={500} textAlign={'center'} textTransform={'uppercase'}>{offer.name}</Typography>
                                        </Box>
                                        {offer?.job_offers?.map((job) => {
                                            return (
                                                <Box sx={{background: 'white', display: 'block', alignItems: 'center', justifyContent: 'center', margin: '40px 0', width: '100%'}}>
                                                    <Box onClick={() => {onClickJob(job.id)}} sx={{display: isMobile ? 'block' : 'flex', alignItems: 'center', justifyContent: 'space-between', height: '100%', padding: '10px 20px', cursor: 'pointer', height: 'auto'}}>
                                                        <Typography variant="h2" fontSize={isMobile ? '16px' : '19px'} fontWeight={800} maxWidth={isMobile ? '100%' : '60%'} mb={isMobile ? 1 : 0}>{job.name}</Typography>
                                                        <Box display={isMobile ? 'block' : 'flex'} height={'100%'} alignItems={'center'} justifyContent={'space-between'} width={isMobile ? '100%' : '40%'}>
                                                            <Typography variant="h2" fontSize={'19px'} fontWeight={500} mb={1}>{job.address}</Typography>
                                                            <Typography variant="h2" fontSize={'19px'} fontWeight={500}>{'+ info'}</Typography>
                                                        </Box>
                                                    </Box>
                                                    <Collapse in={collapsedJobs[job.id] || false} timeout="auto" sx={{ width: "100%", background: 'white', maxWidth: '100%', borderTop: '2px solid #333' }}>
                                                    <Box>
                                                            {job?.day != "" ? <Typography variant="h3" fontSize={'19px'} color={'#575756'} fontWeight={400} fontFamily={'Montserrat'} mt={2} ml={2}>{job?.day}</Typography> : <></>}
                                                            {job?.description != "" ? <Typography variant="h3" fontSize={'19px'} color={'#575756'}  fontWeight={800} fontFamily={'Montserrat'} mb={3} mt={3} ml={2}>{'Descripció:'}</Typography> : <></>}
                                                            <div dangerouslySetInnerHTML={{ __html: job?.description }} style={{marginLeft: 18, marginBottom: 40, fontFamily: 'Montserrat', color:'#575756' }}></div>
                                                            {job?.requirements != "" ? <Typography variant="h3" fontSize={'19px'} fontWeight={800} mb={1} ml={2} color={'#575756'} fontFamily={'Montserrat'} >{'Requisits:'}</Typography> : <></>}
                                                            <div dangerouslySetInnerHTML={{ __html: job?.requirements }} style={{marginLeft: 18, marginBottom: 40, fontFamily: 'Montserrat', color:'#575756'}}></div>
                                                            {job?.contact != "" ? <Typography variant="h3" fontSize={'19px'} color={'#575756'}  fontWeight={800} mb={1} ml={2} fontFamily={'Montserrat'}>{'Contacte:'}</Typography> : <></>}
                                                            <div dangerouslySetInnerHTML={{ __html: job?.contact }} style={{marginLeft: 18, marginBottom: 40, fontFamily: 'Montserrat', color:'#575756'}}></div>
                                                        </Box>
                                                    </Collapse>
                                                </Box>
                                            )
                                        })}
                                    </Grid>
                                )
                            })}
                            
                        </Grid>
                    <XXSS isMobile={isMobile} social={web?.social}/>
                    </Container>
                    <Footer isMobile={isMobile} text_footer={web?.text_footer} subtext_footer={web?.subtext_footer} color={pages?.main_color} sponsors={web?.sponsors}/>
                </Box>
            </Page>
	);
}
