import React, { useCallback, useEffect, useState } from "react";
import Page from "../../components/layouts/Page";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { get } from "../../API/API";
import { Box, Card, CardContent, Container, Grid, Button, Typography } from "@mui/material";
import Caixa from "../../components/utils/Caixa";
import { motion, AnimatePresence } from "framer-motion";
import Posts from "../../components/utils/Posts";
import Footer from "../../layouts/public/Footer";
import XXSS from "../../components/utils/XXSS";
import Ofertes from "../../components/utils/Ofertes";
import PrintIcon from '@mui/icons-material/Print';

function SegonaPagina(props) {

	const [loading, setLoading] = useState(true);
    const [pagines, setPagines] = useState([]);
    const [posts, setPosts] = useState([]);
    const [pages, setPages] = useState([]);
    const { name, id } = useParams();

    const navigate = useNavigate();

    const [width, setWidth] = useState(100);
	const div = useCallback((node) => {
		if (node !== null) {
			setWidth(node.getBoundingClientRect().width);
		}
	}, []);

    const variants = {
		hidden: { opacity: 0, x: 0, y: 50 },
		enter: { opacity: 1, x: 0, y: 0 },
		exit: { opacity: 0, x: 0, y: -100 },
	};

    useEffect(() => {
        const obtenir = async () => {
            const { result, message } = await get("menu/" + id);
            setPagines(result);
            setLoading(false);
        };
        obtenir();

        const obtenirPosts = async () => {
            const { result, message } = await get("posts/" + id);
            setPosts(result);
        }
        obtenirPosts();

        const obtenirPages = async () => {
            const { result, message } = await get("pages/" + id);
            setPages(result);
        }
        obtenirPages();
      }, []);
	
	function getGridSize(size) {
        switch (size) {
            case "1":
                return 4;
            case "2":
                return 6;
            case "3":
                return 12;
            default: 
                return 4
        }
    }

    if (pages?.offer_categories?.length > 0) {
        return (
            <Ofertes pages={pages} web={props.web} isMobile={props.isMobile}/>
        )
    } else {
        return (
            <Page title="Agència de desenvolupament del Berguedà" timeout={3000} padding={10} paddingTop={60}>
                <Box 
                    display={props.isMobile ? 'block' : 'flex'}
                    position={'relative'} 
                    height={props.isMobile ? '100%' : '100vh'} 
                    width={'100%'} 
                    margin={'auto'} 
                    sx={{
                        backgroundColor: "#eaeaea",
                        backgroundImage: `url(`+pages?.image_background?.url+`)`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        overflowY: 'auto'
                    }}
                >
                    <Container maxWidth={props.isMobile ? false : 'xl'} sx={{maxWidth: '1200px'}} disableGutters={props.isMobile ? true : false}>
                        <Grid 
                            container 
                            spacing={2} 
                            width={'100%'}
                            sx={{
                                padding: pages?.filters?.length > 0 && props.isMobile ? "40px 0 0 0" : "100px 0",
                                maxWidth: (pages?.childs?.length > 0 ? '830px' : "1200px"),
                                margin: 'auto',
                                width: '100%',
                                marginTop: '50px'
                            }}
                        >
                            {props.isMobile ? 
                            <Box 
                                style={{ 
                                    zIndex: 3,
                                    position: "relative",
                                    transition: '.2s',
                                    width: '100%',
                                    display: 'flex'
                                }}
                            >
                                {pages?.filters?.map((filter) => {
                                    return (
                                            <Button 
                                                key={filter.id}
                                                target={filter.external_link == "" ? '_self' : '_blank'}
                                                variant="contained" 
                                                color="info" 
                                                sx={{
                                                    background: ('white'),
                                                    marginTop: '10px',
                                                    borderRadius: 0,
                                                    width: '50%',
                                                    fontFamily: 'Montserrat'
                                                }}
                                                onClick={() => {
                                                    navigate('/'+filter.link)
                                                }}
                                            >{filter.name}</Button>
                                    )
                                })}
                            </Box>
                            : <></>}
                            {pages?.printable ? 
                                props.isMobile ?
                                <Grid item md={12} xs={12} sx={{paddingLeft: props.isMobile ? '0 !important' : 'unset'}}><Box sx={{position: 'relative', height: '40px'}}><a class="printableIcon" target="blank" href={"https://adbergueda.herokuapp.com/imprimir/"+pages?.slug+".pdf"}><PrintIcon htmlColor="#333"/></a></Box></Grid>
                                :
                                <Box sx={{position: 'absolute', right: '10%'}}><a class="printableIcon" target="blank" href={"https://adbergueda.herokuapp.com/imprimir/"+pages?.slug+".pdf"}><PrintIcon htmlColor="#333"/></a></Box>
                            : <></>}
                            {pages?.childs?.length > 0 ? 
                                <>
                                    <Grid item md={12} xs={12} sx={{paddingLeft: props.isMobile ? '0 !important' : 'unset'}}>
                                        <Box sx={{background: 'white', height: '50px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                            <Typography variant="h2" fontSize={'24px'} fontWeight={700} textAlign={'center'}>{pages.name}</Typography>
                                        </Box>
                                    </Grid>
                                    {pagines.map((pagina, index) => {
                                        return (
                                            <Grid key={index} item md={6} xs={12} sx={{paddingLeft: props.isMobile ? '0 !important' : 'unset'}}>
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        background: 'white',
                                                        cursor: 'pointer',
                                                        background: 'white',
                                                        height: '150px',
                                                        overflow: 'hidden'
                                                    }}
                                                >
                                                    <a 
                                                        href={(pagina?.no_link ? null : (pagina?.external_link == "" || pagina?.external_link == null ? pages?.slug+'/'+pagina?.url : pagina.external_link))}
                                                        mx={2} 
                                                        display={'flex'} 
                                                        padding={'10px'}
                                                        target={pagina?.external_link == "" || pagina?.external_link == null ? "" : (pagina?.internal_link ? "" : "_blank")}
                                                        style={{
                                                            textDecoration: 'none',
                                                            margin: '0 !important', 
                                                            width: '100%',
                                                            display: 'flex',
                                                            margin: '10px',
                                                            overflow: 'hidden'
                                                        }}
                                                    >
                                                        <img src={pagina.image} alt="image" style={{width: '130px', height: '130px', margin: 0}}/>
                                                        <Box
                                                            sx={{
                                                                marginLeft: '10px'
                                                            }}
                                                        >
                                                            <Typography variant="h3" height={'40px'} fontSize={'14px'} color={'#575757'} fontWeight={700}>{pagina.name}</Typography>
                                                            <Typography
                                                                variant="body1"
                                                                className="segona-pagina"
                                                                fontSize={'12px'}
                                                                width={'100%'}
                                                                dangerouslySetInnerHTML={{ __html: pagina.description }}
                                                            ></Typography>
                                                        </Box>
                                                    </a>
                                                </Box>
                                            </Grid>
                                        )
                                    })}
                                </> : 
                                <>
                                    <Box sx={{marginTop: props.isMobile ? 0 : "90px", marginBottom: props.isMobile ? 0 : "90px", background: 'white'}}>
                                        {pages?.image_header != null ? <Box sx={{width: '100%', height: props.isMobile ? 'auto' : 200, overflow: 'hidden'}}><img src={pages?.image_header} alt="image_header" style={{width: '100%', objectFit: 'cover', margin: 0}}/></Box> : <></>}
                                        {pages?.name != "" ? <Typography variant="h3" fontSize={'24px'} margin={'20px 0px'} padding={'40px 20px 0'} color={'#575757'} fontWeight={700}>{pages.name}</Typography> : <></>}
                                        <Grid container sx={{position: 'relative'}}>
                                            <Grid item md={8} xs={12}>
                                                <Typography
                                                    padding={2}
                                                    dangerouslySetInnerHTML={{ __html: pages?.content }}
                                                ></Typography>
                                            </Grid>
                                            {pages?.image_right != null ? 
                                                <Grid item md={4} xs={12}>
                                                    {props.isMobile ? <></> : <img 
                                                        src={pages.image_right} 
                                                        alt="image_header" 
                                                        style={{
                                                            display: 'block', 
                                                            position: 'absolute',
                                                            right: '50px',
                                                            top: '200px',
                                                            width: '200px',
                                                            height: '200px',
                                                            borderRadius: '50%',
                                                            margin: 0
                                                        }}
                                                    />}
                                                </Grid> :
                                                <></>
                                            }
                                        </Grid>
                                    </Box>
                                </>
                            }
                        </Grid>
                    {props.isMobile ? <></> : <Box 
                        style={{ 
                            zIndex: 3,
                            position: "fixed",
                            left: -40,
                            top: '50%',
                            transform: 'translateY(-50%)',
                            transition: '.2s',
                            width: 300,
                            display: 'flex',
                            flexDirection: 'column'
                        }}
                    >
                        {pages?.filters?.map((filter) => {
                            return (
                                    <Button 
                                        key={filter.id}
                                        target={filter.external_link == "" ? '_self' : '_blank'}
                                        variant="contained" 
                                        color="info" 
                                        sx={{
                                            background: ('white'),
                                            marginTop: '10px',
                                            fontFamily: 'Montserrat'
                                        }}
                                        onClick={() => {
                                            navigate('/'+filter.link)
                                        }}
                                    >{filter.name}</Button>
                            )
                        })}
                    </Box>}
                    <XXSS isMobile={props.isMobile} social={props.web?.social}/>
                    </Container>
                    <Footer isMobile={props.isMobile} text_footer={props.web?.text_footer} subtext_footer={props.web?.subtext_footer} color={pages?.main_color} sponsors={props.web?.sponsors}/>
                </Box>
            </Page>
        );
    }
    

}

export default SegonaPagina;
