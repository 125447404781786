import React, { forwardRef, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";
import { Fade } from "@mui/material";
import theme from "../../theme";
import { motion, AnimatePresence } from "framer-motion";

const Page = forwardRef(({ children, title = "", timeout=10, paddingTop=104, ...rest }, ref) => {
	const [appear, setApperar] = useState(false);
	useEffect(() => {
		window.scrollTo(0, 0);
		setApperar(true);
		// setTimeout(() => {
		// 	setApperar(true);
		// }, 300);
	}, []);

	const variants = {
		hidden: { opacity: 0, x: 0, y: 50 },
		enter: { opacity: 1, x: 0, y: 0 },
		exit: { opacity: 0, x: 0, y: -100 },
	};

	return (
		<div
			ref={ref}
			{...rest}
			style={{
				backgroundColor: 'white',
				position: 'relative',
				minHeight: '100vh'
			}}
		>
			<Helmet>
				<title>{'Agència de desenvolupament del Berguedà'}</title>
			</Helmet>
			<motion.div
				variants={variants} // Pass the variant object into Framer Motion
				style={{height: '100vh'}}
				initial="hidden" // Set the initial state to variants.hidden
				animate="enter" // Animated state to variants.enter
				exit="exit" // Exit state (used later) to variants.exit
				transition={{ type: "linear" }}
			>
				<AnimatePresence>
					<div
						style={{
							backgroundColor: '#eee',
							overflow: 'hidden',
							height: '100%'
						}}
					>
						{children}
					</div>
				</AnimatePresence>
			</motion.div>
		</div>
	);
});

Page.propTypes = {
	children: PropTypes.node.isRequired,
	title: PropTypes.string,
};

export default Page;
