import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
	AppBar,
	Box,
	Drawer,
	Hidden,
	IconButton,
	Toolbar,
	useMediaQuery,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router";
import Menu from "./Menu";

const useStyles = makeStyles((theme) => ({
	main: {
		width: "100%",
		zIndex: 100,
		top: 0
	},
	nav: {
		transition: "0.2s",
	},
	navBar: {
		backgroundColor: "white !important",
		boxShadow: "none",
		position:"relative",
	},
	navBarDown: {
		backgroundColor: "transparent",
		boxShadow: "none",
		maskSize: "cover",
		maskRepeat: "no-repeat",
		maskPosition: "center",
	},
	logo: {
		fill: theme.color.primary,
	},
	maskTop: {
		// backgroundImage: `url(${bg})`,
		width: "100%",
	},
}));

const TopBarPublic = ({ toggleDrawer, setHideMenu, isMobile, className, menu, setOpen, open, ...rest }) => {
	const classes = useStyles();
	const navigate = useNavigate();

	


	return (
		<Box className={classes.main} sx={{position: isMobile ? 'absolute' : 'fixed'}}>
			<Menu toggleDrawer={toggleDrawer} menu={menu} isMobile={isMobile} setOpen={setOpen} open={open}/>
		</Box>
	);
};

TopBarPublic.propTypes = {
	className: PropTypes.string,
	onMobileNavOpen: PropTypes.func,
};

export default TopBarPublic;
