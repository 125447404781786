import React, { useEffect, useState } from "react";
import { useRoutes } from "react-router-dom";
import routes from "./routes";
import theme from "./theme";
import { ThemeProvider } from "@mui/material/styles";
import { Grow, IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
import Loading from "./components/layouts/Loading";
import { get } from "./API/API";
import Buscador from "./components/utils/Buscador";

function App() {
	const [loading, setLoading] = useState(false);
  const [isMobile, setIsMobile] = useState(getIsMobile());
	const [web, setWeb] = useState([]);

	function getIsMobile() {
        return window.innerWidth < 1096;
    }


  useEffect(() => {
    const obtenir = async () => {
      const { result } = await get("web");
      setWeb(result);
      setLoading(false);
    };
    obtenir();
  }, []);



	const routing = useRoutes(routes(isMobile, web));



	return (
		<ThemeProvider theme={theme}>
      {routing}
		</ThemeProvider>
	);
}

export default App;
