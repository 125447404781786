import { Box, Button, Typography } from "@mui/material";

import { useState } from "react";
import { useNavigate } from "react-router-dom";
export default function CaixaHome({ data, isMobile }) {
    const [scale, setScale] = useState(1);
    const navigate = useNavigate();
    function onCaixa() {
        setScale(1.03);
    }
    function outCaixa() {
        setScale(1);
    }

    function onClickCaixa() {
        if (data?.external_link === "" || data?.external_link === null) {
            navigate('/'+data?.url);
        } else {
            window.open(data.external_link, '_blank'); 
        }
    }

	return (
            
        <Box 
            style={{ 
                width: "100%", 
                height: "100%", 
                background: data?.background, 
                display: 'flex', 
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                height: '150px',
                borderRadius: isMobile ? 0 : '10px',
                cursor: 'pointer',
                transform: 'scale('+scale+')',
                transition: '.2s',
                overflow: 'hidden'
            }}
            onMouseEnter={onCaixa}
            onMouseLeave={outCaixa}
        >
            <Button onClick={() => {onClickCaixa()}}  mx={2} display={'flex'} sx={{textDecoration: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%'}}>
                <img src={data?.icon} style={{width: '65px', height: '65px', margin: 0}}/>
                <Typography variant="caixa" color={"#fff"} textAlign={'center'}>{data?.name}</Typography>
            </Button>
        </Box>
	);
}
