import React from "react";
import PublicLayout from "./layouts/public";
import Home from "./views/public/Home";
import Pagina from "./views/public/Pagina";
import SegonaPagina from "./views/public/SegonaPagina";
import TerceraPagina from "./views/public/TerceraPagina";
import Noticia from "./views/public/Noticia";
import Noticies from "./views/public/Noticies";
import Resultats from "./views/public/Resultats";
import QuartaPagina from "./views/public/QuartaPagina";

const routes = (isMobile, web) => [
	{
		path: "/",
		element: <PublicLayout isMobile={isMobile} web={web}/>,
		children: [
			{ path: "/", element: <Home isMobile={isMobile} web={web}/> },
			{ path: "/:name", element: <Pagina isMobile={isMobile} web={web}/> },
			{ path: "/:name/:id", element: <SegonaPagina isMobile={isMobile} web={web}/> },
			{ path: "/:name/:id/:desc", element: <TerceraPagina isMobile={isMobile} web={web}/> },
			{ path: "/:name/:id/:desc/:quart", element: <QuartaPagina isMobile={isMobile} web={web}/> },
			{ path: "/noticia/:noticia", element: <Noticia isMobile={isMobile} web={web}/> },
			{ path: "/noticies", element: <Noticies isMobile={isMobile} web={web}/> },
			{ path: "/cerca/:paraula", element: <Resultats isMobile={isMobile} web={web}/> },

		],
	},
];

export default routes;
