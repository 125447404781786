import React, { useCallback, useEffect, useRef, useState } from "react";
import Page from "../../components/layouts/Page";
import { useNavigate, useParams } from "react-router-dom";
import { get } from "../../API/API";
import { Box, Button, Card, CardContent, Container, Grid, Link, Skeleton, Typography } from "@mui/material";
import Caixa from "../../components/utils/Caixa";
import { motion, AnimatePresence, useInView } from "framer-motion";
import Posts from "../../components/utils/Posts";
import Footer from "../../layouts/public/Footer";
import XXSS from "../../components/utils/XXSS";
import background from "../../images/posts-background.jpg";
import { styled } from "@mui/material/styles";

function Resultats(props) {

	const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [pages, setPages] = useState([]);
    const navigate = useNavigate();
    const { paraula } = useParams();

    const ref = useRef(null)
    const isInView = useInView(ref);

    let count = 0;


    useEffect(() => {
        const obtenir = async () => {
            const { result, message } = await get("search?text=" + paraula);
            setData(result);
        };
        obtenir();
    }, []);

    
	return (
		<Page title="ADBerguedà" timeout={3000} padding={10}>
			<Box 
                id="container"
                display={props.isMobile ? 'block' : 'flex'}
                position={'relative'} 
                height={props.isMobile ? '100%' : '100vh'} 
                width={'100%'} 
                margin={'auto'} 
                sx={{
                    backgroundColor: "white",
                    backgroundImage: `url(`+background+`)`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    overflowY: 'auto',
                }}
            >
                <Container maxWidth='lg' sx={{marginTop: '200px'}}>
                    <Grid 
                        container 
                        spacing={2}
                        sx={{
                            padding: "20px",
                            maxWidth: ("100%"),
                            margin: 'auto',
                            width: '100%',
                            background: 'white'
                        }}
                    >
                        <Grid item md={12} xs={12}><Typography variant='h1' fontSize={'32px'} fontWeight={'800'}>{'Resultats de la cerca: "' + paraula + '"'}</Typography></Grid>
                        <Grid item md={12} xs={12}><Typography variant='h2' fontSize={'24px'} fontWeight={'800'} margin={'20px 0'}>{'Pàgines'}</Typography></Grid>
                        <Box
                            sx={{
                                margin: '0px 0px 80px',
                                padding: '0 0 0 40px'
                            }}
                        >
                            {
                                data?.pages?.length == 0 ?
                                <Typography variant="body1">No s'han trobat pàgines amb aquest text</Typography>
                                :
                                data?.pages?.map((page, index) => {
                                    return (
                                        <Grid key={index} item md={12} xs={12}>
                                            <Box>
                                                <Typography variant='h5' fontSize={'14px'} fontWeight={'800'} margin={'20px 0'}>{page.name}</Typography>
                                                <Typography variant='body1' fontSize={'12px'} fontWeight={'300'} >{page.description}</Typography>
                                                <Link href={'/'+page.path} sx={{color: '#2F4796', cursor: 'pointer'}}  mx={2} margin={'12px 0'}><Typography color='#2F4796' variant='body1' fontSize={'16px'} fontWeight={'300'} >{page.path}</Typography></Link>

                                            </Box>
                                        </Grid>
                                    )
                                })
                            }
                        </Box>
                        <Grid item md={12} xs={12}><Typography variant='h2' fontSize={'24px'} fontWeight={'800'} margin={'20px 0'}>{'Notícies'}</Typography></Grid>
                        <Box
                            sx={{
                                margin: '0px 0px 80px',
                                padding: '0 0 0 40px'
                            }}
                        >
                            {
                                data?.posts?.length == 0 ?
                                <Typography variant="body1">No s'han trobat notícies amb aquest text</Typography>
                                :
                                data?.posts?.map((page, index) => {
                                    return (
                                        <Grid key={index} item md={12} xs={12}>
                                            <Box>
                                                <Typography variant='h5' fontSize={'14px'} fontWeight={'800'} margin={'20px 0'}>{page.name}</Typography>
                                                <Typography variant='body1' fontSize={'12px'} fontWeight={'300'} >{page.description}</Typography>
                                                <Link href={'/noticia/'+page.path} sx={{color: '#2F4796', cursor: 'pointer'}}  mx={2} margin={'12px 0'}><Typography color='#2F4796' variant='body1' fontSize={'16px'} fontWeight={'300'} >{page.path}</Typography></Link>

                                            </Box>
                                        </Grid>
                                    )
                                })
                            }
                        </Box>
                    </Grid>
                    <XXSS isMobile={props.isMobile} social={props.web?.social}/>
                </Container>
			    <Footer isMobile={props.isMobile} text_footer={props.web?.text_footer} subtext_footer={props.web?.subtext_footer} color={pages?.main_color} sponsors={props.web?.sponsors}/>
            </Box>
		</Page>
	);
}

const NoticiaCustom = styled(Box)(({ theme }) => ({
	backgroundColor: 'transparent', 
    cursor: 'pointer',
    display:'flex',
    width:'100%',
    transition: '.2s',
	"&:hover": {
		background: '#cdcdcd'
	},
}));

export default Resultats;
